import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonList,
  IonItem,
  IonLabel,
  IonListHeader,
  IonAvatar,
  IonThumbnail,
  IonSkeletonText,
} from "@ionic/react";
import { withRouter } from "react-router";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import "react-multi-carousel/lib/styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getMultiFilteredData } from "../../modalconfig";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import { getCityInLang, getLangState } from "../../utility/languageTranslator";
import FooterMenu from "../../components/FooterMenu";

const ViewPlacesCard = ({ history }) => {
  const [filter, setFilter] = useState(null);
  const [data, setDetails] = useState([]);
  const [skdata, setData] = useState();
  const [filterName, setfilterName] = useState("");
  const [langCode, setlangCode] = useState("en");

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Filter Details: ${filterName}, ${filter?.label}`,
        screenClass: "Filter Details",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Filter Details: ${filterName}, ${filter?.label}`,
          screen_class: "Filter Details",
          additional_context: { filter: filter?.label, subfilter: filterName },
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    if (filter?.label && filterName) setAnalyticsScreen();
  }, [filter?.label, filterName]);

  useIonViewDidEnter(async () => {
    let filterUnderDetails = await JSON.parse(
      localStorage.getItem("filterUnderDetails")
    );
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    const filterObj = JSON.parse(localStorage.getItem("filterDetails")) || null;
    setFilter(filterObj);
    setfilterName(
      languageCode === "en"
        ? filterUnderDetails?.filtername
        : filterUnderDetails?.filternameLang
    );
    let res = await getMultiFilteredData("places", {
      type: filterObj.label,
      [`${filterUnderDetails.filterType}`]:
        filterUnderDetails?.filtername || "",
    });
    setDetails(res);
    setData({});
  });

  useIonViewDidLeave(() => {
    setDetails([]);
  });

  useIonViewDidLeave(() => {
    setFilter(null);
  });

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon
                className=""
                onClick={() => history.goBack()}
              />
              <h4 className="title-txt">
              {filterName || ""}</h4>
              <ArrowBackIosNewRoundedIcon
                className="visibilityHidden"
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent className="backgroundGraphics">
        {skdata ? (
          <>
            <div className="columnContainer">
              <div className="bannerLeft">
                <img src={filter?.icon || ""} alt="img" />
                <div className="labelDiv">
                  {filter?.label_lang?.[langCode]
                    ? filter?.label_lang?.[langCode]
                    : filter?.label}
                </div>
              </div>
              <div className="bg-grey">
                <div className="img-card">
                  <div className="padding-box">
                    <div className="flex-head">
                      <div className="container">
                        <h4 className="inner-title">{filterName || ""}</h4>
                      </div>
                    </div>
                    <div className="container">
                      <div className="gridCard">
                        {data?.map((item) => {
                          return (
                            <div className="cardItem" key={item._id}>
                              <div
                                className={`caros-card ${
                                  item.type[0] === "Suggested Itinerary"
                                    ? "itinerary"
                                    : ""
                                }`}
                                style={{
                                  paddingBottom: "10px",
                                  marginBottom: "10px",
                                }}
                                onClick={async () => {
                                  await localStorage.setItem(
                                    "placeDetails",
                                    JSON.stringify(item)
                                  );
                                  history.push("/particularplaceinformation");
                                }}
                              >
                                <img alt=" " src={item?.img?.[0]} />
                                <h5>
                                  {item?.name_lang?.[langCode]
                                    ? item?.name_lang?.[langCode]
                                    : item?.name}
                                </h5>
                                <div>
                                  <h6
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                      padding: "0 14px",
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {console.log("testing city....", item)}
                                    <LocationOnIcon />{" "}
                                    {langCode === "en"
                                      ? `${item.city}, ${item.state}`
                                      : `${getCityInLang(
                                          item.city,
                                          langCode
                                        )}, ${getLangState()?.[item.state]}`}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container">
              <div className="ion-padding custom-skeleton">
                <IonSkeletonText animated style={{ width: "60%" }} />
                <IonSkeletonText animated />
                <IonSkeletonText animated style={{ width: "88%" }} />
                <IonSkeletonText animated style={{ width: "70%" }} />
                <IonSkeletonText animated style={{ width: "60%" }} />
              </div>
              <IonList>
                <IonListHeader>
                  <IonLabel>
                    <IonSkeletonText animated style={{ width: "20%" }} />
                  </IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonAvatar slot="start">
                    <IonSkeletonText animated />
                  </IonAvatar>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonThumbnail slot="start">
                    <IonSkeletonText animated />
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonSkeletonText
                    animated
                    style={{ width: "27px", height: "27px" }}
                    slot="start"
                  />
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
              </IonList>
            </div>
          </>
        )}
      </IonContent>
      <FooterMenu history={history} langCode={langCode}/>
    </IonPage>
  );
};

export default withRouter(ViewPlacesCard);
