import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonAlert,
  IonActionSheet,
} from "@ionic/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useHistory, useLocation } from "react-router-dom";
import DesktopHeader from "../../components/DesktopHeader";
import moment from "moment";
import { APIFY, HTTP_CLIENT } from "../../services/Api";

const Profile = () => {
  const history = useHistory();
  const location = useLocation();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [user, setUser] = useState(null);
  const [showLogoutActionSheet, setShowLogoutActionSheet] = useState(false);

  // Fetching userDetails from localStorage after component mounts
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUser(userDetails);
  }, [location.key]);

  const handleAlertDismiss = (input) => {
    console.log("i am ...", user, input);
    if (input === "confirmed") {
      if (!user?.id) return;
      user.isActive = false;
      HTTP_CLIENT(APIFY(`/users/${user.id}`), "PUT", user)
        .then(() => {
          localStorage.removeItem("userDetails");
          history.push("/home");
        })
        .catch((err) => {
          console.error("Error deleting account:", err);
        });
    }
    setShowDeleteAlert(false);
  };

  const logOut = () => {
    localStorage.removeItem("userDetails");
    history.push("/home");
    setShowLogoutActionSheet(false); // Ensures logout sheet is dismissed
  };

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav" style={{ background: "#fff" }}>
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon onClick={() => history.goBack()} />
              <img
                onClick={() => history.push("/settings")}
                alt="Settings"
                src={"./assets/icon/settings.png"}
                height={25}
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="container">
          <h2 style={{ paddingLeft: "25px" }}>My Profile</h2>
          <div className="coverArea">
            <div className="profileDetails">
              <div className="profile-pic-container">
                <img
                  alt="Profile"
                  style={{ borderRadius: "50%" }}
                  src={user?.profileUrl || "./assets/icon/account.png"}
                />
              </div>
              <div className="name">
                <h3>Welcome,</h3>
                <h2>{user?.name || "User"}</h2>
                <p>
                  Last Login :{" "}
                  {moment(user?.lastLogin || null).format("MMM D, YYYY")}
                </p>
              </div>
            </div>
          </div>

          <div className="profile-additional-options">
            <ul>
              <li onClick={() => history.push("/edit-profile")}>
                <div className="option-menu-icon">
                  <img alt="Edit Profile" src={"./assets/icon/pencil.png"} />
                </div>
                <div className="option-menu-label">Edit Profile</div>
              </li>
              <li onClick={() => history.push("/change-password")}>
                <div className="option-menu-icon">
                  <img
                    alt="Change Password"
                    src={"./assets/icon/padlock.png"}
                  />
                </div>
                <div className="option-menu-label">Change Password</div>
              </li>
              <li onClick={() => setShowDeleteAlert(true)}>
                <div className="option-menu-icon">
                  <img
                    alt="Delete Account"
                    src={"./assets/icon/delete-user.png"}
                  />
                </div>
                <div className="option-menu-label">Delete Account</div>
              </li>
              <li>
                <div className="option-menu-icon">
                  <img alt="Bookings" src={"./assets/icon/appointment.png"} />
                </div>
                <div className="option-menu-label">Bookings</div>
              </li>
              <li onClick={() => setShowLogoutActionSheet(true)}>
                <div className="option-menu-icon">
                  <img alt="Logout" src={"./assets/icon/log-out.png"} />
                </div>
                <div className="option-menu-label">Logout</div>
              </li>
            </ul>
          </div>
        </div>

        {/* Delete Account Alert */}
        <IonAlert
          header="Are you sure you want to delete your account?"
          isOpen={showDeleteAlert}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                handleAlertDismiss("cancel");
                console.log("Alert canceled");
              },
            },
            {
              text: "Yes",
              role: "confirm",
              handler: () => {
                handleAlertDismiss("confirmed");
                console.log("Alert confirmed");
              },
            },
          ]}
          onDidDismiss={() => setShowDeleteAlert(false)}
        ></IonAlert>

        {/* Logout Action Sheet */}
        <IonActionSheet
          header="Are you sure you want to logout?"
          isOpen={showLogoutActionSheet}
          onDidDismiss={() => setShowLogoutActionSheet(false)}
          buttons={[
            {
              text: "Logout",
              role: "destructive",
              handler: logOut,
            },
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                console.log("Logout canceled");
              },
            },
          ]}
        ></IonActionSheet>
      </IonContent>

      <div className="version">Version 1.0.0</div>
    </IonPage>
  );
};

export default Profile;
