import React, { useState, useEffect, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useHistory } from "react-router-dom";
import DesktopHeader from "../../components/DesktopHeader";
import { APIFY, HTTP_CLIENT } from "../../services/Api";

const Settings = () => {
  const history = useHistory();
  const [menuVisible, setMenuVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [user, setUser] = useState(null);
  const [lang, setLang] = useState("en");
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setLang(languageCode || "en");

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      setUser(userDetails);
      setNotificationsEnabled(userDetails?.notifications || false);
    }
  }, []);

  const showMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLang = (lang) => {
    localStorage.setItem("languageCode", lang);
    setLang(lang);
  };

  const handleNotificationToggle = (enabled) => {
    if (!user) return null;
    setNotificationsEnabled(enabled);
    user.notifications = enabled;
    HTTP_CLIENT(APIFY(`/users/${user.id}`), "PUT", user)
      .then((res) => {
        if (res?.data) {
          const newDetails = res?.data;
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...user, ...newDetails })
          );
        }
      })
      .catch((err) => {
        console.error("Update failed:", err);
      });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <div className="large-header">
          <ArrowBackIosNewRoundedIcon onClick={() => history.goBack()} />
          <h2>Settings</h2>
        </div>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="container">
          <div id="inbox-list">
            {/* Language Selection */}
            <div
              className="ion-item"
              routerDirection="none"
              lines="none"
              detail={false}
              onClick={showMenu}
            >
              <div className="sidebar_img_crop">
                <img alt=" " src={"./assets/icon/translate.svg"} height={25} />
              </div>
              <IonLabel style={{ marginLeft: "10px" }}>
                Choose Language
                <p style={{ marginTop: "5px", fontWeight: "normal" }}>
                  {lang === "en" ? "English" : "Hindi"}
                </p>
              </IonLabel>
              <div className="dropDownMenuWrap" ref={dropdownRef}>
                {menuVisible ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                {menuVisible && (
                  <div className="dropDownMenu">
                    <ul>
                      <li onClick={() => handleLang("hi")}>Hindi</li>
                      <li onClick={() => handleLang("en")}>English</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Notifications Toggle */}
            <div
              className="ion-item"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <div className="sidebar_img_crop">
                <img
                  alt=" "
                  src={"./assets/icon/notification.svg"}
                  height={25}
                />
              </div>
              <IonLabel style={{ marginLeft: "10px" }}>Notifications</IonLabel>
              <IonToggle
                checked={notificationsEnabled}
                onIonChange={(e) => handleNotificationToggle(e.detail.checked)}
              />
            </div>

            {/* App Version */}
            <div
              className="ion-item"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <div
                className="sidebar_img_crop"
                style={{ alignSelf: "flex-start" }}
              >
                <img alt=" " src={"./assets/icon/android.svg"} height={25} />
              </div>
              <IonLabel style={{ marginLeft: "10px", marginTop: "5px" }}>
                App version
                <p
                  style={{
                    color: "#777777",
                    padding: "5px 0 0",
                    fontSize: "14px !important",
                    fontWeight: "500",
                  }}
                >
                  v1.0.0
                </p>
              </IonLabel>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
