import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import volume from "../images/volume.png";
import mute from "../images/mute.png";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { useIonViewDidLeave } from "@ionic/react";

const MyTextToSpeach = forwardRef(({ speech }, ref) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  useIonViewDidLeave(() => {
    TextToSpeech.stop();
  });

  useEffect(() => {
    console.log("isSpeaking changed to:", isSpeaking);
  }, [isSpeaking]);

  const speak = async () => {
    setIsSpeaking(true);
    try {
      await TextToSpeech.speak({
        text: speech,
        lang: "en-US",
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
        category: "ambient",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const stop = async () => {
    setIsSpeaking(false);
    await TextToSpeech.stop();
  };

  // Expose the stop function to the parent via the ref
  useImperativeHandle(ref, () => ({
    stop,
  }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {isSpeaking ? (
        <img
          alt=""
          src={mute}
          style={{ minWidth: "30px", width: "30px", height: "30px" }}
          onClick={stop}
        />
      ) : (
        <img
          alt=""
          src={volume}
          style={{ minWidth: "30px", width: "30px", height: "30px" }}
          onClick={speak}
        />
      )}
    </div>
  );
});

export default MyTextToSpeach;
