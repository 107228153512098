/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router";
import { IonHeader, IonContent, IonToolbar, IonModal } from "@ionic/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import homeIcon from "../images/footerHome.png";
import searchIcon from "../images/footerSearch.png";
import shareIcon from "../images/footerShare.png";
import { IonRow, IonCol, IonIcon } from "@ionic/react";
import { closeCircle, search } from "ionicons/icons";
import { getLangLabel } from "../utility/languageTranslator";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { isBrowser, isMobile } from "react-device-detect";
import { APIFY, HTTP_CLIENT } from "../services/Api";
import { getStateList } from "../modalconfig";
import { markers } from "../utility/commonMethods";

const FooterMenu = ({ history, langCode }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const inputRef = useRef(null);

  const { transcript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [searchInput, setSearchInput] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [recognizedText, setRecognizedText] = useState("");
  const [places, setPlaces] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [speechRecognition, setSpeechRecognition] = useState({
    SpeechRecognitionModule: null,
    startListening: null,
    handleStartListening: null,
    startListen: null,
    stopListen: null,
  });

  useEffect(() => {
    const speechRecognitionFunctions = deviceDetect();
    setSpeechRecognition(speechRecognitionFunctions);
  }, [isMobile, isBrowser]);

  const deviceDetect = () => {
    console.log("deviceDetected is called.");
    let SpeechRecognitionModule;
    let startListening, handleStartListening, startListen, stopListen;

    if (isMobile) {
      const CapacitorSpeechRecognition =
        require("@capacitor-community/speech-recognition").SpeechRecognition;
      SpeechRecognitionModule = CapacitorSpeechRecognition;

      startListening = () => {
        const available = CapacitorSpeechRecognition.available();
        try {
          if (available) {
            CapacitorSpeechRecognition.start({
              language: "en-US",
              maxResults: 2,
              prompt: "Say something",
              partialResults: true,
              popup: false,
            });
            setIsListening(true);

            CapacitorSpeechRecognition.addListener("partialResults", (data) => {
              if (data.matches && data.matches.length > 0) {
                console.log("Recognized text:", data.matches[0]);
                setRecognizedText(data.matches[0]);
              }
            });
          }
        } catch (error) {
          console.error("Failed to start listening:", error);
        }
      };

      handleStartListening = async () => {
        try {
          setRecognizedText("");
          startListening();
          setTimeout(() => {
            CapacitorSpeechRecognition.stop();
            console.log("Stopped listening after 3 seconds");
            setIsListening(false);
          }, 3000);
        } catch (error) {
          console.error("Failed to start listening:", error);
        }
      };
    } else if (isBrowser) {
      SpeechRecognitionModule = SpeechRecognition;

      startListen = () => {
        if (browserSupportsSpeechRecognition) {
          SpeechRecognition.startListening();
          setIsListening(true);
        } else {
          console.log("Speech recognition not supported in this browser.");
        }
      };

      stopListen = () => {
        try {
          setRecognizedText("");
          startListen();
          setTimeout(() => {
            SpeechRecognition.stopListening();
            console.log("Stopped listening after 3 seconds");
            setIsListening(false);
          }, 3000);
        } catch (error) {
          console.error("Failed to stop listening:", error);
        }
      };
    }

    return {
      SpeechRecognitionModule,
      startListening,
      handleStartListening,
      startListen,
      stopListen,
    };
  };

  const handleOnSearch = (text) => {
    console.log("this is handle");
    setSearchInput(text || "");
    getPlaces(text);
  };

  useEffect(() => {
    if (recognizedText || transcript) {
      console.log("useEffect transcript", recognizedText, "tran=>", transcript);
      getPlaces(isMobile ? recognizedText : transcript);
    } else {
      console.log(
        "this is else part",
        typeof recognizedText + "second" + typeof transcript
      );
    }
  }, [isListening, recognizedText, transcript]);

  useEffect(() => {
    setSearchInput(isMobile ? recognizedText : transcript);
  }, [isListening, isMobile, recognizedText, transcript]);

  const handleOnSelect = (item) => {
    if (item._id) {
      setPlaces([]);
      setSearchInput("");
      localStorage.setItem("placeDetails", JSON.stringify(item));
      if (item?.cityKey) {
        history.push(`/placeinformation`);
      } else {
        history.push(`/particularplaceinformation`);
      }
    } else {
      if (item?.stateType) {
        localStorage.setItem(
          "placeDetails",
          JSON.stringify({ state: item?.name })
        );
        history.push(`/cityList`);
        setPlaces([]);
        setSearchInput("");
      }
    }
    closeModal();
  };
  const getCityData = async () => {
    let cityListData = [];
    const cities = await JSON.parse(localStorage.getItem("cities"));
    if (!cities && cityList?.length === 0) {
      const res1 = await getStateList({});
      cityListData = res1?.city?.map((obj) => {
        obj.name = obj?.city;
        return obj;
      });
      setCityList(cityListData);
      localStorage.setItem("cities", JSON.stringify(res1?.city || []));
    }
    if (cities && cityList?.length === 0) {
      cityListData = cities?.map((obj) => {
        obj.name = obj?.city;
        return obj;
      });
      setCityList(cityListData);
    }
    if (cityList?.length > 0) cityListData = cityList;
    return cityListData;
  };
  async function getPlaces(text) {
    if (text?.length > 0) {
      await HTTP_CLIENT(APIFY("/places"), "GET", null, {
        name: text,
        limit: 15,
      })
        .then((data) => {
          getCityData().then((cityListData) => {
            const states = markers
              ?.filter((state) =>
                state?.name?.toLowerCase().includes(text?.toLowerCase())
              )
              ?.map((state) => {
                return { name: state?.name, stateType: true };
              });

            const cityListNow = cityListData?.filter((obj) =>
              obj?.city?.toLowerCase().includes(text?.toLowerCase())
            );
            setPlaces([...states, ...cityListNow, ...data?.data]);
          });
        })
        .catch((err) => { });
    }
  }
  const handleOnClear = () => {
    setSearchInput("");
    setPlaces([]);
  };

  const openSearchModal = () => {
    setIsSearchOpen(true);
  };

  const closeModal = () => {
    setIsSearchOpen(false);
    setSearchInput("");
    setPlaces([]);
  };

  const handleModalDidPresent = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input after modal is presented
    }
  };

  return (
    <>
      <div className="footerMenu">
        <div className="footerMenu-wrap">
          <div className="f-menu-item" onClick={() => history.push("/home")}>
            <img src={homeIcon} />
            <p>{getLangLabel(langCode)?.home}</p>
          </div>
          <div className="f-menu-item" onClick={openSearchModal}>
            <img src={searchIcon} />
            <p>Search</p>
          </div>
          <div className="f-menu-item">
            <img src={shareIcon} />
            <p>Share</p>
          </div>
        </div>
      </div>
      <IonModal
        isOpen={isSearchOpen}
        onDidDismiss={() => setIsSearchOpen(false)}
        onDidPresent={handleModalDidPresent}
      >
        <IonHeader style={{ background: "#fff" }}>
          <IonToolbar className="t-bar">
            <div className="top-nav" style={{ background: "#fff" }}>
              <div
                className="nav_inner globalSearchModal"
                style={{ position: "relative" }}
              >
                <ArrowBackIosNewRoundedIcon onClick={closeModal} />
                <input
                  type="text"
                  ref={inputRef}
                  value={searchInput}
                  onChange={(e) => handleOnSearch(e.target.value)}
                  placeholder={getLangLabel(langCode)?.search}
                />
                {searchInput?.length > 0 && (
                    <IonIcon
                      icon={closeCircle}
                      onClick={() => handleOnClear()}
                    />
                )}

                <button
                  onClick={
                    isMobile
                      ? speechRecognition.handleStartListening
                      : speechRecognition.stopListen
                  }
                  style={{
                    minHeight: "44px",
                    color: "#000",
                    borderRadius: "24px",
                  }}
                >
                  {isListening ? (
                    "Listening"
                  ) : (
                    <KeyboardVoiceIcon style={{ color: "#000" }} />
                  )}
                </button>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundGraphics">
          <div className="columnContainer">
            <div className="bg-grey">
              <div className="placeList">
                <ul>
                  {places?.length > 0 && (
                    <div>
                      {places.map((obj) => (
                        <li>
                          <IonRow
                            className="resultList"
                            key={obj._id}
                            onClick={() => handleOnSelect(obj)}
                          >
                            <IonCol
                              size="auto"
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: "10px",
                                paddingRight: 0,
                                display: "flex",
                                alignItems: "center",
                                fontSize: "20px",
                              }}
                            >
                              <IonIcon
                                icon={search}
                                style={{ color: "grey" }}
                              />
                            </IonCol>
                            <IonCol
                              style={{
                                textAlign: "left",
                                paddingLeft: "10px",
                              }}
                            >
                              {obj.name}
                            </IonCol>
                          </IonRow>
                        </li>
                      ))}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default withRouter(FooterMenu);
