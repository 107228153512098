import React, { useState, useEffect } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useHistory } from "react-router-dom";
import DesktopHeader from "../../components/DesktopHeader";
import eyeOpen from "../../images/eye-open.png";
import eyeClose from "../../images/eye-close.png";
import moment from "moment";
import { APIFY, HTTP_CLIENT } from "../../services/Api";

const ChangePassword = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [passView, setPassView] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Toggle password visibility
  const togglePassView = (field) => {
    setPassView((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  // Load user details from localStorage
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUser(userDetails);
  }, []);

  // Validate the form inputs
  const validateForm = () => {
    if (!oldPass || !newPass || !confirmPass) {
      setError("All fields are required");
      return false;
    }
    if (newPass !== confirmPass) {
      setError("New password and confirmation password do not match");
      return false;
    }
    setError("");
    return true;
  };

  // Handle the Save button click
  const handleSave = () => {
    if (validateForm()) {
      const payload = {
        old_password: oldPass,
        new_password: newPass,
      };
      HTTP_CLIENT(APIFY(`/users/change-password`), "POST", payload)
        .then((res) => {
          if (res?.data) {
            setSuccess("Password changed successfully.");
            setOldPass("");
            setNewPass("");
            setConfirmPass("");
          }
        })
        .catch(({ response }) => {
          const msg = response?.data?.errors[0];
          setError(msg || "");
        });
    }
  };

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav" style={{ background: "#fff" }}>
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon onClick={() => history.goBack()} />
              <img
                onClick={() => history.push("/settings")}
                alt=" "
                src={"./assets/icon/settings.png"}
                height={25}
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="container">
          <h2 style={{ paddingLeft: "25px" }}>Change Password</h2>
          <div className="coverArea">
            <div className="profileDetails">
              <div className="profile-pic-container">
                <img
                  alt="Profile"
                  style={{ borderRadius: "50%" }}
                  src={user?.profileUrl || "./assets/icon/account.png"}
                />
              </div>
              <div className="name">
                <h3>Welcome,</h3>
                <h2>{user?.name || ""}</h2>
                <p>
                  Last Login :{" "}
                  {moment(user?.lastLogin || "").format("MMM D, YYYY")}
                </p>
              </div>
            </div>
          </div>

          <div className="profile-additional-options">
            <ul>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>Old Password</label>
                  <div className="password-container">
                    <input
                      type={passView.oldPassword ? "text" : "password"}
                      value={oldPass}
                      onChange={(e) => setOldPass(e.target.value)}
                      placeholder="Your old password"
                    />
                    <span onClick={() => togglePassView("oldPassword")}>
                      <img
                        alt=""
                        src={passView.oldPassword ? eyeClose : eyeOpen}
                        width={20}
                      />
                    </span>
                  </div>
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>New Password</label>
                  <div className="password-container">
                    <input
                      type={passView.newPassword ? "text" : "password"}
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                      placeholder="Your new password"
                    />
                    <span onClick={() => togglePassView("newPassword")}>
                      <img
                        alt=""
                        src={passView.newPassword ? eyeClose : eyeOpen}
                        width={20}
                      />
                    </span>
                  </div>
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>Confirm Password</label>
                  <div className="password-container">
                    <input
                      type={passView.confirmPassword ? "text" : "password"}
                      value={confirmPass}
                      onChange={(e) => setConfirmPass(e.target.value)}
                      placeholder="Confirm password"
                    />
                    <span onClick={() => togglePassView("confirmPassword")}>
                      <img
                        alt=""
                        src={passView.confirmPassword ? eyeClose : eyeOpen}
                        width={20}
                      />
                    </span>
                  </div>
                </div>
              </li>
              {error && (
                <p className="error-message" style={{ color: "red" }}>
                  {error}
                </p>
              )}
              {success && (
                <p className="success-message" style={{ color: "green" }}>
                  {success}
                </p>
              )}
            </ul>
          </div>

          <div className="submitBtn">
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
